// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// IMPORT / REQUIRE JS


require("jquery");

require('jquery-ui-bundle');
// import 'jquery-ui';
// require("jquery-ui/ui/widgets/sortable")




require("@rails/activestorage").start();
import Rails from '@rails/ujs';
Rails.start();
require("turbolinks").start();
require("bootstrap");

// note: STIMULUS code
// import "controllers" //this is where my stimulus code is


// IMPORT CUSTOM JS
// note: this is where I put my JS pack code.
// require("packs/custom")
// require("packs/wizard")

//under this line add new stuff ----------------------------------------------------



//this is old and it doesn't work this way:
// require ("../stylesheets/application")

// IMPORT / REQUIRE JS

require('magnific-popup')
// import 'magnific-popup';



import inputmask from "inputmask"
window.inputmask = inputmask;

import flatpickr from "flatpickr"
window.flatpickr = flatpickr;

import quill from "quill"
window.Quill = quill;

import tippy from 'tippy.js'
window.tippy = tippy;


import("js/theme.min");

// import("packs/steps");



import AOS from 'aos';
// note: import AOS begin
document.addEventListener('DOMContentLoaded', function () {
  AOS.init({
    startEvent: 'turbolinks:load' // if you are using turbolinks
  });
});
// note: import AOS end





